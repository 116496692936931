<script setup lang="ts">
import {useField} from 'vee-validate';

defineOptions({
    inheritAttrs: true,
});

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    initialValue: {
        type: [String, Number, Boolean],
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    styled: {
        type: Boolean,
        default: true,
    },
    checkedValue: {
        type: [String, Number, Boolean],
        default: true,
    },
    uncheckedValue: {
        type: [String, Number, Boolean],
        default: false,
    },
});

const {checked, value} = useField(() => props.name, undefined, {
    type: 'checkbox',
    initialValue: props.initialValue,
    checkedValue: props.checkedValue,
    uncheckedValue: props.uncheckedValue,
});

const checkboxCss = props.styled
    ? 'checkbox checkbox-primary checkbox-sm'
    : 'rounded border-gray-300 text-primary shadow-sm focus:ring-primary';
</script>

<template>
    <input :id="name" v-model="value" type="checkbox" :name="name" :checked="checked" :class="checkboxCss" />
</template>
